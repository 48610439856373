


















































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { NotificationStatus } from '~/types/components/Notification/NotificationStatus';
import { ButtonTextSize } from '~/types/components/Buttons/ButtonTextSize';
import { notificationCssClasses } from '~/helpers/notifications/notificationCssClasses';
import { useUiNotification } from '~/composables';

export default defineComponent({
  name: 'NotificationToast',
  props: {
    id: {
      type: String,
      required: true
    },
    status: {
      type: String as PropType<NotificationStatus>,
      default: NotificationStatus.info
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    endingLinkText: {
      type: String,
      default: ''
    },
    endingLinkSlug: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { remove: removeUiNotification } = useUiNotification();
    const onRemove = () => {
      removeUiNotification(props.id);
    };

    const borderColor = computed(() => notificationCssClasses[props.status]?.border || '');
    const backgroundColor = computed(() => notificationCssClasses[props.status]?.background || '');
    const iconClass = computed(() => notificationCssClasses[props.status]?.icon || '');

    return {
      borderColor,
      backgroundColor,
      iconClass,
      ButtonTextSize,
      onRemove
    };
  }
});
