





















import { defineComponent } from '@nuxtjs/composition-api';
import { useUiNotification } from '~/composables';
import NotificationToast from '~/components/atoms/Notification/NotificationToast.vue';

export default defineComponent({
  name: 'Notification',
  components: {
    NotificationToast
  },
  setup () {
    const { notifications } = useUiNotification();

    return {
      notifications
    };
  }
});
